.App {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: 16px;

  --primaryColor: #484868;
}

@media (min-width: 720px) {
  .App {
    flex-direction: row;
  }
}

button {
  outline: 0;
}

button:not(:disabled) {
  cursor: pointer;
}

input,
textarea,
select,
button {
  font-size: 1em;
}

.icon svg {
  height: 1em;
  width: 1em;
}
