.App {
  position: relative;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
  font-size: 16px;

  --primaryColor: #484868;
}

@media (min-width: 720px) {
  .App {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
}

button {
  outline: 0;
}

button:not(:disabled) {
  cursor: pointer;
}

input,
textarea,
select,
button {
  font-size: 1em;
}

.icon svg {
  height: 1em;
  width: 1em;
}

.Grid {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0.3em;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0.3em;
  grid-auto-rows: auto;
}

@media (min-width: 480px) {
  .Grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 720px) {
  .Grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 900px) {
  .Grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1300px) {
  .Grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 1950px) {
  .Grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (min-width: 2400px) {
  .Grid {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media (min-width: 3000px) {
  .Grid {
    grid-template-columns: repeat(8, 1fr);
  }
}

@media (min-width: 4000px) {
  .Grid {
    grid-template-columns: repeat(9, 1fr);
  }
}

.Grid .wrapper {
  position: relative;
}

.Grid .remove-button {
  background-color: #FFFFFF;
  border-radius: 50%;
  position: absolute;
  top: 0em;
  right: 0em;
  font-size: 0em;
  z-index: 10;
  border: none;
  outline: none;
  margin: 0 auto;
  fill: var(--primaryColor);
  padding: 0.1em;
  width: 1.2em;
  height: 1.2em;
}

.Grid .wrapper:hover .remove-button {
  font-size: 1.3em;
}

.Grid .missing {
  fill: #dddddd;
}


.TimerWidget {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.3em;
  grid-template-areas:
    "title title"
    "buttons buttons"
    "timer timer";
}

.TimerWidget .title {
  font-size: 1.3em;
  grid-area: title;
}

.TimerWidget .button-row {
  grid-area: buttons;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.TimerWidget .toggle,
.TimerWidget .clear {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.5em;
  fill: #484868;
}

.TimerWidget .timer {
  grid-area: timer;
  font-size: 2.5em;
}


.Input {
  position: relative;
}

.Input .Input-input {
  border: 0;
  outline: none;
  border-bottom: 0.1em solid #DDDDDD;
  -webkit-transition: border-bottom 0.3s ease-out;
  transition: border-bottom 0.3s ease-out;
  margin: 0.2em;
  width: calc(100% - 0.4em);
  color: inherit;
}

.Input.has-label .Input-input {
  margin-top: calc(0.2em + (0.8 * 0.8em));
}

.Input .Input-input:focus {
  border-bottom: 0.1em solid #484868;
}

.Input .Input-label {
  position: absolute;
  color: #525252;
  -webkit-transition: border-bottom 0.3s ease-out, top 0.3s ease-out, left 0.3s ease-out, font-size 0.3s ease-out;
  transition: border-bottom 0.3s ease-out, top 0.3s ease-out, left 0.3s ease-out, font-size 0.3s ease-out;
  top: 1em;
  left: 0.2em;
}

.Input .Input-input:focus ~ .Input-label,
.Input.has-value .Input-input ~ .Input-label {
  top: 0;
  font-size: 0.8em;
}

.Input .Input-required {
  position: absolute;
  right: 0;
  bottom: 0.6em;
  color: #EA0000;
}

.TimerDisplay {
  font-family: monospace;
  text-align: center;
}

.Card {
  background-color: #FFFFFF;
  border-radius: 0.5em;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.NotesWidget {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.3em;
  grid-template-areas:
    "title title"
    "notes notes";
}

.NotesWidget .title {
  font-size: 1.3em;
  grid-area: title;
}

.NotesWidget .notes {
  grid-area: notes;
}

.Textarea {

}

.Textarea-input {
  resize: vertical;
  min-height: 5em;
  outline: none;
  border: none;
  margin: 0.2em;
  margin-bottom: 0;
  color: inherit;
  width: calc(100% - 0.4em);
  padding: 0;
}

.ChecklistWidget {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.3em;
  grid-template-areas:
    "title title"
    "list list"
    "button button";
}

.ChecklistWidget .title {
  grid-area: title;
  font-size: 1.3em;
}

.ChecklistWidget .list {
  grid-area: list;
  resize: vertical;
  margin: 0.2em;
}

.ChecklistWidget .list .Checklist-item {
  display: -webkit-flex;
  display: flex;
  margin: 0.3em 0;
}

.ChecklistWidget .list .check-text {
  font-size: 1.3em;
  width: 100%;
}

.ChecklistWidget .list .checked .check-text {
  color: #9e9e9e;
}

.ChecklistWidget .list .remove {
  display: none;
  grid-area: button;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.3em;
  fill: var(--primaryColor);
  padding: 0.1em;
  width: 1.2em;
  height: 1.2em;
  align-self: center;
}

.ChecklistWidget .list .Checklist-item:hover .remove {
  display: inline;
  display: initial;
}

.ChecklistWidget .add {
  grid-area: button;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.5em;
  fill: var(--primaryColor);
}

.Checkbox {
  position: relative;
}

.Checkbox .Checkbox-input {
  height: 0;
  width: 0;
  font-size: 0;
}
.Checkbox .Checkbox-input + .Checkbox-label {
  padding-left: 2em;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  display: block;
  height: 1.1em;
  line-height: normal;
  line-height: initial;
  font-size: 1em;
  color: #1d4383;
  z-index: 1;
  top: -0.6em;
}
.Checkbox .Checkbox-input + .Checkbox-label::before,
.Checkbox .Checkbox-input + .Checkbox-label::after {
  content: '';
  top: 0;
  left: 0;
  margin-top: 0;
  position: absolute;
}
.Checkbox .Checkbox-input + .Checkbox-label::before {
  top: 0.25em;
  left: 0.1em;
  width: 0.4em;
  height: 0.7em;
  border-top: 0 solid transparent;
  border-left: 0 solid transparent;
  border-right: 0.1em solid #ffffff;
  border-bottom: 0.1em solid #ffffff;
  -webkit-transform: rotateZ(45deg) scale(1);
          transform: rotateZ(45deg) scale(1);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
  -webkit-transition: border 0.2s ease-in, -webkit-transform 0.2s ease-in;
  transition: border 0.2s ease-in, -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in, border 0.2s ease-in;
  transition: transform 0.2s ease-in, border 0.2s ease-in, -webkit-transform 0.2s ease-in;
  z-index: 1;
}
.Checkbox .Checkbox-input + .Checkbox-label::after {
  background-color: var(--primaryColor);
  border: 0.1em solid var(--primaryColor);
  border-radius: 50%;
  height: 1.2em;
  width: 1.2em;
}
.Checkbox .Checkbox-input:not(:checked) + .Checkbox-label {
  color: #6b6b6b;
}
.Checkbox .Checkbox-input:not(:checked) + .Checkbox-label::before {
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.Checkbox .Checkbox-input:not(:checked) + .Checkbox-label::after {
  background-color: transparent;
  border: 0.1em solid #9e9e9e;
}

.JsWidget {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.3em;
  grid-template-areas:
    "title title"
    "history history"
    "prompt prompt";
}

.JsWidget .title {
  font-size: 1.3em;
  grid-area: title;
}

.JsWidget .history-list {
  grid-area: history;
  overflow-y: scroll;
  max-height: 10em;
}

.JsWidget .history-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.JsWidget .history-item.error {
  background-color: #FDF3F4;
  color: #C80000;
}

.JsWidget .history-item:not(:first-child) {
  border-top: 1px solid #DDDDDD;
}

.JsWidget .input {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.JsWidget .prompt-container {
  font-size: 1.3em;
  grid-area: prompt;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.JsWidget .direction-indicator {
  width: 1em;
  height: 1em;
  color: #969696;
  font-size: 0.5em;
  margin: 0 0.5em;
}

.JsWidget .direction-indicator.active {
  color: #3B7FF1;
}

.JsWidget .direction-indicator:before {
  width: 1em;
  height: 1em;
  display: block;
  content: ' ';
  border-style: solid;
  border-radius: 0.2em;
  border-top-width: 0.2em;
  border-right-width: 0.2em;
  border-bottom-width: 0;
  border-left-width: 0;
}
.JsWidget .direction-indicator.right:before {
  -webkit-transform: rotate(45deg) translate(-0.14em, 0.14em);
          transform: rotate(45deg) translate(-0.14em, 0.14em);
}
.JsWidget .direction-indicator.left:before {
  -webkit-transform: rotate(-135deg) translate(-0.14em, 0.14em);
          transform: rotate(-135deg) translate(-0.14em, 0.14em);
}

.Menu {
  background-color: #484868;
  color: #FFFFFF;
  display: -webkit-flex;
  display: flex;
}

.Menu .menu-section {
  display: -webkit-flex;
  display: flex;
  padding: 0 0.2em;
}

.Menu .menu-section:first-child {
  padding-left: 0;
}

.Menu .menu-section:not(:first-child) {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.Menu .menu-item {
  background-color: rgba(255, 255, 255, 0);
  border: 0;
  outline: none;
  color: inherit;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
  cursor: pointer;
  height: 4em;
  width: 4em;
}

.Menu .menu-item .icon {
  fill: #FFFFFF;
  font-size: 2em;
}

.Menu .menu-item .item-title {

}

.Menu .menu-item:hover,
.Menu .menu-item.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.Menu .menu-item:hover .item-title,
.Menu .menu-item.active .item-title {
  text-decoration: underline;
}

.Menu .menu-section.info-area {
  margin-left: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.Menu .menu-section.info-area .menu-icon-link {
  fill: rgba(255, 255, 255, 0.2);
}

.Menu .menu-section.info-area .menu-icon-link:hover,
.Menu .menu-section.info-area .menu-icon-link.active {
  fill: rgba(255, 255, 255, 0.5);
}

@media (min-width: 720px) {
  .Menu,
  .Menu .menu-section {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .Menu .menu-section {
    padding: 0.2em 0;
  }

  .Menu .menu-section:first-child {
    padding-top: 0;
  }

  .Menu .menu-section:last-child {
    padding-bottom: 0;
  }

  .Menu .menu-section:not(:first-child) {
    border-left: none;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  .Menu .menu-section.info-area {
    margin-left: 0;
    margin-top: auto;
  }
}

.MessageList {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.MessageList .Message {
  color: #464646;
  fill: #464646;
  background-color: #E2E2E2;
  padding: 0.5em;
  margin: 0.3em;
  border-radius: 0.3em;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  width: 300px;
}
.MessageList .Message.info {
  color: #009B95;
  fill: #009B95;
  background-color: #B8F5E7;
}
.MessageList .Message.info .button-list .action {
  color: #009B95;
  border-color: #009B95;
  background-color: #B8F5E7;
}

.MessageList .Message.warn {
  color: #9F6000;
  fill: #9F6000;
  background-color: #FEEFB3;
}
.MessageList .Message.warn .button-list .action {
  color: #9F6000;
  border-color: #9F6000;
  background-color: #FEEFB3;
}

.MessageList .Message.error {
  color: #D8000C;
  fill: #D8000C;
  background-color: #FFBABA;
}
.MessageList .Message.error .button-list .action {
  color: #D8000C;
  border-color: #D8000C;
  background-color: #FFBABA;
}

.MessageList .Message .header {
  display: -webkit-flex;
  display: flex;
}

.MessageList .Message .header .title {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
}

.MessageList .Message .header .close {
  font-size: 1.5em;
  width: 1em;
  height: 1em;
  padding: 0;
  border: none;
  background-color: transparent;
}

.MessageList .Message .message {
  margin: 0.5em 0;
}

.MessageList .Message .button-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.MessageList .Message .button-list .action {
  font-weight: bold;
  border-style: solid;
  border-width: 0.2em;
  border-radius: 1em;
  padding: 0.2em 0.5em;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  min-height: 100vh;
}

