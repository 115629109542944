.Grid {
  flex-grow: 1;
  height: fit-content;
  margin: 0.3em;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0.3em;
  grid-auto-rows: auto;
}

@media (min-width: 480px) {
  .Grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 720px) {
  .Grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 900px) {
  .Grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1300px) {
  .Grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 1950px) {
  .Grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (min-width: 2400px) {
  .Grid {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media (min-width: 3000px) {
  .Grid {
    grid-template-columns: repeat(8, 1fr);
  }
}

@media (min-width: 4000px) {
  .Grid {
    grid-template-columns: repeat(9, 1fr);
  }
}

.Grid .wrapper {
  position: relative;
}

.Grid .remove-button {
  background-color: #FFFFFF;
  border-radius: 50%;
  position: absolute;
  top: 0em;
  right: 0em;
  font-size: 0em;
  z-index: 10;
  border: none;
  outline: none;
  margin: 0 auto;
  fill: var(--primaryColor);
  padding: 0.1em;
  width: 1.2em;
  height: 1.2em;
}

.Grid .wrapper:hover .remove-button {
  font-size: 1.3em;
}

.Grid .missing {
  fill: #dddddd;
}

