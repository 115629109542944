.NotesWidget {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.3em;
  grid-template-areas:
    "title title"
    "notes notes";
}

.NotesWidget .title {
  font-size: 1.3em;
  grid-area: title;
}

.NotesWidget .notes {
  grid-area: notes;
}
