.ChecklistWidget {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.3em;
  grid-template-areas:
    "title title"
    "list list"
    "button button";
}

.ChecklistWidget .title {
  grid-area: title;
  font-size: 1.3em;
}

.ChecklistWidget .list {
  grid-area: list;
  resize: vertical;
  margin: 0.2em;
}

.ChecklistWidget .list .Checklist-item {
  display: flex;
  margin: 0.3em 0;
}

.ChecklistWidget .list .check-text {
  font-size: 1.3em;
  width: 100%;
}

.ChecklistWidget .list .checked .check-text {
  color: #9e9e9e;
}

.ChecklistWidget .list .remove {
  display: none;
  grid-area: button;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.3em;
  fill: var(--primaryColor);
  padding: 0.1em;
  width: 1.2em;
  height: 1.2em;
  align-self: center;
}

.ChecklistWidget .list .Checklist-item:hover .remove {
  display: initial;
}

.ChecklistWidget .add {
  grid-area: button;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.5em;
  fill: var(--primaryColor);
}
