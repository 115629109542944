.JsWidget {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.3em;
  grid-template-areas:
    "title title"
    "history history"
    "prompt prompt";
}

.JsWidget .title {
  font-size: 1.3em;
  grid-area: title;
}

.JsWidget .history-list {
  grid-area: history;
  overflow-y: scroll;
  max-height: 10em;
}

.JsWidget .history-item {
  display: flex;
  align-items: center;
}

.JsWidget .history-item.error {
  background-color: #FDF3F4;
  color: #C80000;
}

.JsWidget .history-item:not(:first-child) {
  border-top: 1px solid #DDDDDD;
}

.JsWidget .input {
  flex-grow: 1;
}

.JsWidget .prompt-container {
  font-size: 1.3em;
  grid-area: prompt;
  display: flex;
  align-items: center;
}

.JsWidget .direction-indicator {
  width: 1em;
  height: 1em;
  color: #969696;
  font-size: 0.5em;
  margin: 0 0.5em;
}

.JsWidget .direction-indicator.active {
  color: #3B7FF1;
}

.JsWidget .direction-indicator:before {
  width: 1em;
  height: 1em;
  display: block;
  content: ' ';
  border-style: solid;
  border-radius: 0.2em;
  border-top-width: 0.2em;
  border-right-width: 0.2em;
  border-bottom-width: 0;
  border-left-width: 0;
}
.JsWidget .direction-indicator.right:before {
  transform: rotate(45deg) translate(-0.14em, 0.14em);
}
.JsWidget .direction-indicator.left:before {
  transform: rotate(-135deg) translate(-0.14em, 0.14em);
}
