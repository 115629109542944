.TimerWidget {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.3em;
  grid-template-areas:
    "title title"
    "buttons buttons"
    "timer timer";
}

.TimerWidget .title {
  font-size: 1.3em;
  grid-area: title;
}

.TimerWidget .button-row {
  grid-area: buttons;
  display: flex;
  justify-content: space-evenly;
}

.TimerWidget .toggle,
.TimerWidget .clear {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.5em;
  fill: #484868;
}

.TimerWidget .timer {
  grid-area: timer;
  font-size: 2.5em;
}

